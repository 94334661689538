import clsx from "clsx"
import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"
import classes from "./CallIndicatorIcon.module.scss"

const CallIndicatorIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={clsx(classes.root, className)}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_20127_240216)">
            <path
              d="M8.01747 6.29376C7.91282 6.68382 7.67497 7.0168 7.32296 7.24512C7.08512 7.44491 6.81874 7.68275 6.73312 7.77789C6.60944 7.96816 6.59992 8.01573 6.84728 8.26308L9.74895 11.1838C9.9963 11.4311 10.0248 11.4216 10.2912 11.2409C10.3103 11.2123 10.5386 10.9459 10.7384 10.7081C11.2426 9.91846 12.3367 9.71867 13.2595 10.2514C13.7447 10.5464 15.3525 11.5548 15.9233 12.6394C16.3514 13.448 16.2087 14.4279 15.5618 15.0749L14.9244 15.7218C13.9825 16.6732 12.9075 17.1584 11.7373 17.1584C10.2246 17.1584 8.64537 16.3307 7.03756 14.7133L3.32723 10.9745C2.07143 9.69965 1.29131 8.45336 1.01542 7.25463C0.663413 5.72294 1.12006 4.26735 2.32829 3.0496L2.96571 2.41219C3.60312 1.76526 4.60207 1.61304 5.40121 2.04115C6.53334 2.64051 7.57032 4.372 7.7701 4.71449C8.06503 5.22823 8.16017 5.78953 8.01747 6.29376Z"
              fill={color}
            />
            <path
              d="M15.6731 1.68804L12.332 5.04761L13.344 6.05396L16.685 2.69439L15.6731 1.68804Z"
              fill={color}
            />
            <path
              d="M11.2713 0.899902H9.84424V4.76245H11.2713V0.899902Z"
              fill={color}
            />
            <path
              d="M17.5498 7.04565H13.6968V8.4727H17.5498V7.04565Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_20127_240216">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8508 13.5371C16.0046 13.0779 16.0046 11.8535 15.3123 11.4708L13.3124 10.476C12.6971 10.1699 11.9279 10.3995 11.6202 11.0117C11.4664 11.2413 11.3895 11.3943 11.3895 11.3943C10.5433 11.3943 8.85111 10.0933 8.23576 9.55765C7.6204 8.94543 6.38969 7.26183 6.38969 6.42003C6.38969 6.42003 6.54353 6.34351 6.77429 6.26698C7.38964 5.96087 7.69732 5.19559 7.38965 4.58337L6.38969 2.67019C6.00509 2.05797 4.77439 1.98145 4.31287 2.1345C3.46676 2.36408 2.77448 2.9763 2.38988 3.66505C1.85145 4.6599 2.08221 5.96086 2.38988 7.26183C3.00524 9.09848 4.15903 10.7821 5.69742 12.2361C7.15889 13.7666 8.85111 14.9146 10.6972 15.5268C12.0048 15.8329 13.3124 16.0625 14.3124 15.5268C15.0047 15.0676 15.62 14.3789 15.8508 13.5371Z"
            fill={color}
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.7739 2.06665C13.543 2.06665 15.7737 4.28594 15.7737 7.04092"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.7739 4.59204C12.1585 4.59204 13.3123 5.73995 13.3123 7.11744"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default CallIndicatorIcon
