import React, { useCallback, useState } from "react"
import { IAdvisor } from "../../types"
import ExtendedSuggestions from "./ExtendedSuggestions"
import MinimizedSuggestions from "./MinimizedSuggestions"
import { TMode } from "./types"

interface IExpertsSuggestions {
  experts: IAdvisor[]
  minimizedExpertsClassName?: string
  isMinimalExpertView?: boolean
}

const ExpertsSuggestions: React.FC<IExpertsSuggestions> = ({
  experts,
  minimizedExpertsClassName,
  isMinimalExpertView,
}) => {
  const [mode, setMode] = useState<TMode>("minimized")

  const clickToggler = useCallback((mode: TMode) => {
    setMode(mode)
  }, [])

  return (
    <>
      {mode === "minimized" && (
        <MinimizedSuggestions
          experts={experts.slice(0, 3)}
          clickToggler={clickToggler}
          rootClassName={minimizedExpertsClassName}
        />
      )}
      {mode === "extended" && (
        <ExtendedSuggestions
          isMinimalExpertView={isMinimalExpertView}
          experts={experts.slice(0, 10)}
          clickToggler={clickToggler}
        />
      )}
    </>
  )
}

export default ExpertsSuggestions
