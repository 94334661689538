import React from "react"
import useInterval from "../../hooks/useInterval"
import { IExpertsSuggestionsJSON } from "../../types"
import { useFetchExperts } from "../Experts/ExpertsService"
import ExpertsSuggestions from "./ExpertsSuggestions"

const EXPERTS_SIZE = 10

interface IExpertsSuggestionsContainerProps {
  config: IExpertsSuggestionsJSON
  minimizedExpertsClassName?: string
  isMinimalExpertView?: boolean
}

const ExpertsSuggestionsContainer: React.FC<IExpertsSuggestionsContainerProps> = ({
  config,
  minimizedExpertsClassName,
  isMinimalExpertView,
}) => {
  /**
   * @note order_by is no more working this way.
   *
   * @todo Fix order_by with new values which Advice Api can accept
   * https://adviqo.atlassian.net/browse/FEM-514
   */
  const { experts: data, refetch } = useFetchExperts("listOfSuggestedExperts", {
    size: EXPERTS_SIZE,
    order_by: config.orderBy,
  })

  useInterval(() => {
    if (refetch) refetch()
  }, config.refetchIntervalMs)

  if (data.content.length === 0) {
    return null
  }

  return (
    <ExpertsSuggestions
      isMinimalExpertView={isMinimalExpertView}
      minimizedExpertsClassName={minimizedExpertsClassName}
      experts={data.content}
    />
  )
}

export default ExpertsSuggestionsContainer
