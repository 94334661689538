import clsx from "clsx"
import React from "react"
import StatusIndicator from "../Experts/StatusIndicator"
import { Avatar, Box, useTheme, AdviqoButton } from "../_common"
import ChevronIcon from "../_common/QuesticoIcon/ChevronIcon"
import { ISuggestionsProps } from "./types"
import classes from "./MinimizedSuggestions.module.scss"

const MinimizedSuggestions: React.FC<ISuggestionsProps> = ({
  experts,
  clickToggler,
  rootClassName,
}) => {
  const theme = useTheme()

  return (
    <Box
      className={clsx(classes.root, rootClassName)}
      onClick={() => clickToggler("extended")}
    >
      <Box className={classes.label}>Jetzt verfügbar!</Box>
      <Box className={classes.list}>
        {experts.map(({ avatar, status, listing_number: listingNumber }) => (
          <Box className={classes.avatarWrapper} key={listingNumber}>
            <Avatar src={avatar} className={classes.avatar} />
            <StatusIndicator
              onlineStatus={status}
              className={classes.indicator}
            />
          </Box>
        ))}
        <AdviqoButton
          testId={"minimized-suggestion-button"}
          buttonClassName={clsx(classes.avatar, classes.toggler)}
          onClick={() => clickToggler("minimized")}
          icon={
            <ChevronIcon
              width={9}
              color={theme.palette.common.white}
              direction="top"
            />
          }
        />
      </Box>
    </Box>
  )
}

export default MinimizedSuggestions
