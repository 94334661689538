import clsx from "clsx"
import React from "react"
import { useRuntimeConfig } from "../../../hooks/runtimeConfig"
import {
  IAdvisorProduct,
  IAdvisorProductType,
  IPromotion,
} from "../../../types"
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  ProductPriceString,
} from "../../_common"
import CallIndicatorIcon from "../../_common/QuesticoIcon/CallIndicatorIcon"
import ChatIndicatorIcon from "../../_common/QuesticoIcon/ChatIndicatorIcon"
import { isCall, isProductFree } from "../Products"
import classes from "./ExpertPrices.module.scss"

function getPrefix(type: IAdvisorProductType) {
  switch (type) {
    case "chat":
      return "Chat"
    case "call":
      return "Anruf"
    default:
      return null
  }
}

interface IExpertHeaderProps {
  products: IAdvisorProduct[]
  promotion?: IPromotion | null
  direction?: "row" | "column"
  color?: "red"
}

const ExpertHeader: React.FC<IExpertHeaderProps> = ({
  products,
  promotion,
  direction = "row",
  color,
}) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const { getRuntimeConfig } = useRuntimeConfig()

  const availableProducts = products.filter((pr) => pr.is_available)

  if (availableProducts.length === 0) {
    return null
  }

  const rootClasses = clsx(classes.root, {
    [classes.desktopColumn]: direction === "column",
    [classes.red]: color === "red",
  })

  return (
    <Box className={rootClasses}>
      {availableProducts.map((product) => {
        const { price, price_sale: priceSale, type } = product

        const isFreeProduct = isProductFree(
          product.type,
          getRuntimeConfig,
          promotion
        )

        const prefix = isDesktop ? getPrefix(type) : undefined
        const iconClassName = clsx(classes.productType, {
          [classes.freeProduct]: isFreeProduct,
        })

        return (
          <Box key={type} className={classes.productContainer}>
            {isCall(type) ? (
              <CallIndicatorIcon className={iconClassName} />
            ) : (
              <ChatIndicatorIcon className={iconClassName} />
            )}

            {prefix && (
              <Typography
                className={classes.pricePrefix}
                component="span"
                display="inline"
              >
                {prefix}
              </Typography>
            )}

            <Typography component="span">
              <ProductPriceString
                price={price}
                priceSale={priceSale}
                allowHighlight
                priceTextColor={
                  priceSale !== null ? theme.palette.secondary.main : undefined
                }
              />
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export default ExpertHeader
