import clsx from "clsx"
import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"
import classes from "./ChatIndicatorIcon.module.scss"

const ChatIndicatorIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={clsx(classes.root, className)}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_20180_268812)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.02987 8.18986H11.9699V6.56986H6.02987V8.18986ZM6.01684 11.5086H11.9568V9.88863H6.01684V11.5086ZM17.0999 9.00098C17.0999 6.41762 15.8849 4.11182 13.996 2.6279C12.619 1.54574 10.8834 0.899902 9.00098 0.899902C4.53302 0.899902 0.899902 4.53302 0.899902 9.00098C0.899902 13.4668 4.53302 17.0999 9.00098 17.0999C10.2732 17.0999 11.5411 16.7953 12.6741 16.2186L17.0999 17.0999L16.0296 13.0218C16.7295 11.8014 17.0999 10.4136 17.0999 9.00098Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_20180_268812">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.65555 15.1444C6.66666 15.6889 7.83333 16 9 16C12.8889 16 16 12.8889 16 9C16 5.11111 12.8889 2 9 2C5.11111 2 2 5.11111 2 9C2 10.2444 2.31111 11.4111 2.85555 12.3444"
            fill={color}
          />
          <path
            d="M5.65555 15.1444C6.66666 15.6889 7.83333 16 9 16C12.8889 16 16 12.8889 16 9C16 5.11111 12.8889 2 9 2C5.11111 2 2 5.11111 2 9C2 10.2444 2.31111 11.4111 2.85555 12.3444"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M5.65555 15.1442L2 15.9998L2.85555 12.3442" fill={color} />
          <path
            d="M5.65555 15.1442L2 15.9998L2.85555 12.3442"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.99715 9.99331C6.44323 9.99331 6.80484 9.6317 6.80484 9.18562C6.80484 8.73955 6.44323 8.37793 5.99715 8.37793C5.55107 8.37793 5.18945 8.73955 5.18945 9.18562C5.18945 9.6317 5.55107 9.99331 5.99715 9.99331Z"
            fill="white"
          />
          <path
            d="M9.18562 9.99331C9.6317 9.99331 9.99331 9.6317 9.99331 9.18562C9.99331 8.73955 9.6317 8.37793 9.18562 8.37793C8.73955 8.37793 8.37793 8.73955 8.37793 9.18562C8.37793 9.6317 8.73955 9.99331 9.18562 9.99331Z"
            fill="white"
          />
          <path
            d="M12.3741 9.99331C12.8202 9.99331 13.1818 9.6317 13.1818 9.18562C13.1818 8.73955 12.8202 8.37793 12.3741 8.37793C11.928 8.37793 11.5664 8.73955 11.5664 9.18562C11.5664 9.6317 11.928 9.99331 12.3741 9.99331Z"
            fill="white"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default ChatIndicatorIcon
