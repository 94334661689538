import React from "react"

import { Typography, useTheme, AdviqoButton } from "../_common"
import ChevronIcon from "../_common/QuesticoIcon/ChevronIcon"
import HorizontalExpertsList from "../Experts/HorizontalExpertsList"
import { CLIENT_TRACKING_EVENT_LIST_ID } from "./constants"
import { ISuggestionsProps } from "./types"
import classes from "./ExtendedSuggestions.module.scss"

const ExtendedSuggestions: React.FC<ISuggestionsProps> = ({
  experts,
  clickToggler,
  isMinimalExpertView,
}) => {
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <AdviqoButton
        buttonClassName={classes.toggler}
        onClick={() => clickToggler("minimized")}
        icon={
          <ChevronIcon
            width={9}
            color={theme.palette.common.white}
            direction="bottom"
          />
        }
        testId="suggestions-toggler"
      />
      <Typography className={classes.label}>
        Jetzt für dich verfügbar
      </Typography>
      <HorizontalExpertsList
        isMinimalExpertView={isMinimalExpertView}
        content={experts.slice(0, 10)}
        emptyElement={<></>}
        loadingComponent={<></>}
        listingName={CLIENT_TRACKING_EVENT_LIST_ID}
        isLoading={false}
        expertRootClassName={classes.expert}
        avatarClassName={classes.avatar}
        onlineStatusClassName={classes.status}
        expertNameClassName={classes.name}
        listClassName={classes.list}
        getClientTrackingProperties={() => ({
          list_id: CLIENT_TRACKING_EVENT_LIST_ID,
        })}
      />
      <div className={classes.line} />
    </div>
  )
}

export default ExtendedSuggestions
